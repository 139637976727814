<template>
  <div >
    <chikyMaps/>
<!--    <clock-name/>-->
  </div>
</template>

<script>
import chikyMaps from './view/chikyMap.vue'
import ClockName from "@/view/components/clock";
export default {
  name: 'App',
  components: {
    chikyMaps,
    ClockName
  }
}
</script>

<style>
</style>
