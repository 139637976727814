<template>
  <div >
    <div class="container">
      <div class="box">
        <div class="clock">
          <div class="hour">
            <div class="hr" id="hr"></div>
          </div>
          <div class="min">
            <div class="mn" id="mn"></div>
          </div>
          <div class="sec">
            <div class="sc" id="sc"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "clockName",
  created() {
    setTimeout(()=>{
      this.count();

    },100);
  },
  methods: {
    count(){
      const deg = 6
      const hr = document.getElementById('hr')
      const mn = document.getElementById('mn')
      const sc = document.getElementById('sc')
      const test = this.$refs.hr;

      setInterval(() => {
        let day = new Date();
        let hh = day.getHours() * 30;
        let mm = day.getMinutes() * deg;
        let ss = day.getSeconds() * deg;
        console.log("时：", hr);
        console.log("分：", mm);
        console.log("秒：", hh);
        hr.style.transform = `rotateZ(${hh + (mm / 12)}deg)`
        mn.style.transform = `rotateZ(${mm}deg)`
        sc.style.transform = `rotateZ(${ss}deg)`
      }, 1000);
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #e91e61, #e91e63 50%, #ffc107 50%, #ffc107);
}

body::after {
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(160deg, #03a9f4, #03a9f4 50%, transparent 50%, transparent);
  animation: animate 5s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}

.container {
  position: relative;
}
/*底部浮动的阴影*/
/*.container::before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -120px;*/
/*  width: 100%;*/
/*  height: 60px;*/
/*  background: radial-gradient(rgba(0, 0, 0, 0.2), transparent, transparent);*/
/*  border-radius: 50%;*/
/*}*/

.box {
  position: relative;
  width: 400px;
  height: 400px;
  backdrop-filter: blur(25px);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  animation: animate 5s ease-in-out infinite;
  animation-delay: -2.5s;
  z-index: 1;
}

.clock {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: radial-gradient(transparent, rgba(255, 255, 255, 0.2)), url('../../clock/img/clock.png');
  background-size: cover;
  border-radius: 50%;
  backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: none;
  border-right: none;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.1), 10px 10px 20px rgba(0, 0, 0, 0.1), 0px 40px 50px rgba(0, 0, 0, 0.2);
}

.clock::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 10000;
}

.hour,
.min,
.sec {
  position: absolute;
}

.hour,
.hr {
  width: 160px;
  height: 160px;
}

.min, .mn {
  width: 190px;
  height: 190px;
}

.sec, .sc {
  width: 230px;
  height: 230px;
}

.hr, .mn, .sc {
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
}

.hr::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 80px;
  background-color: #ff105e;
  border-radius: 6px;
  z-index: 11;
}

.mn::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 90px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 12;
}

.sc::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 150px;
  background-color: rgb(39, 123, 233);
  border-radius: 6px;
  z-index: 13;
}
</style>
