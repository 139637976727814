import axios from "axios";
import {Message} from "element-ui";

const service = axios.create({
    baseURL: 'api',
    timeout: 10000
})

// 响应之前拦截请求，已经拿到请求数据了，在执行then之间进行拦截处理
service.interceptors.response.use(res => {
    // 未设置状态码默认成功状态（200）
    const code = res.data.code || 200;
    // 获取回调信息
    const msg = res.data.msg
    // 显示错误信息
    if (code !== 200) {
        Message({
            message: msg,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(new Error(msg))
    } else {
        return res.data;
    }
}, error => {
    let {message} = error;
    if (message === "Network Error") {
        message = "系统接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error);
});

export default service
