import 'babel-polyfill'
//element-ui
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import VueAMap from 'vue-amap';

Vue.use(ElementUI);
Vue.use(VueAMap);

//vue-amap
VueAMap.initAMapApiLoader({
  key: '65351adea59781c14566ac2aa180a3a0',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geolocation','AMap.ControlBar'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});


new Vue({
  el: '#app',
  render: h => h(App)
});


